import React, { useEffect, useRef } from "react";
import { Typography, useTheme } from "@mui/material";
import "./Integrations.css";

const Integrations = () => {
  const theme = useTheme();
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.setProperty(
        "--background-color",
        theme.palette.background.default
      );
    }
  }, [theme.palette.background.default]);

  const partners = [
    /* { id: 2, name: "UDD Ventures", logo: "/logos/udd.svg" }, */
    { id: 3, name: "Medilink", logo: "/logos/medilink.svg" },
    { id: 4, name: "Reservo", logo: "/logos/reservo.png" },
    { id: 5, name: "Dentalink", logo: "/logos/dentalink.svg" },
    { id: 6, name: "iTera", logo: "/logos/itera.png" },
    { id: 7, name: "agendapro", logo: "/logos/agendapro.svg" },
    { id: 8, name: "Masterkey", logo: "/logos/mk.png" },
  ];

  const doubledPartners = [...partners, ...partners];

  return (
    <section className="integrations-section">
      <div className="container">
        <div className="carousel-wrapper" ref={containerRef}>
          <div className="logos-scroll">
            <div className="logos-track">
              {doubledPartners.map((partner, index) => (
                <div
                  key={`${partner.id}-${index}`}
                  className="partner-logo-container"
                >
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    className="partner-logo"
                  />
                </div>
              ))}
            </div>
            <div className="logos-track" aria-hidden="true">
              {doubledPartners.map((partner, index) => (
                <div
                  key={`${partner.id}-${index}-clone`}
                  className="partner-logo-container"
                >
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    className="partner-logo"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>

      <Typography className="text-center mb-5 mt-3" sx={{ color: "text.secondary" }}>
        Integraciones + Organizaciones que confían en Vita
      </Typography>
    </section>
  );
};

export default Integrations;
